import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Resolve } from '@angular/router';
import {environment} from '../../../environments/environment';
interface Res {
  status: number;
  message:string;
  data:any;
}
@Injectable({
  providedIn: 'root'
})
export class DownloadLinksService {
  footerData;
  constructor(private http: HttpClient) { }

  resolve(){
   return this.http.get<Res>(environment.apiBaseURL+'/v1/common/secret_key',{
      params:new HttpParams().set('domain', 'https://isupplyksa.com').set('secretdbkey', '6666df1a9af9b79708023c0a3e1e3770'),
    }).toPromise().then((data) => {
      if(isDevMode())
        console.log(data.data);
      this.footerData=data.data.settingsData
      return data.data.settingsData
    })   
  }
}
