import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Resolve } from '@angular/router';
import {environment} from '../../../environments/environment';
interface Res {
  status: number;
  message:string;
  data:any;
}
@Injectable({
  providedIn: 'root'
})
export class OrderApiService {
  constructor(private http: HttpClient) { }

  resolve(){
  //console.log(environment.apiBaseURL);

   return this.http.get<Res>(environment.apiBaseURL+'/list_termsConditions',{
      headers:new HttpHeaders().set('secretdbkey', '6666df1a9af9b79708023c0a3e1e3770'),
    }).toPromise().then((data) => {
      if(isDevMode())
        console.log(data.data);
      return data.data 
    })   
  }
}
