import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, isDevMode, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
declare var gtag;

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent implements OnInit {
  applink;

  constructor(private route: ActivatedRoute, private router: Router, private location: Location, private titleService: Title) {
    this.titleService.setTitle(this.titleService.getTitle() + ' - Download our awesome application');
  }

  ngOnInit(): void {
    let os = this.getOS();
    let source = '-';
    const userAgent = navigator.userAgent;
    if(isDevMode()) console.log(userAgent, os);

    // if we want to use user query
    this.route.queryParams.subscribe(params => {
      try{ 
        source = params['s'];
        if(isDevMode()) console.log(source);
      } catch(e){ console.log(e); }
    });

    try{
      this.addTrackingEvent(source, os, userAgent);
      if(isDevMode()) console.log(source, os, userAgent);
    }
    catch(e) {console.log(e);}

    if (os == 'iOS') {
      let iosAppLink = this.route.snapshot.data.DownloadLinksService.key_value.ios_app_url;
      if(isDevMode()) console.log(iosAppLink);
      // //this.router.navigate(iosAppLink);
      // window.location.href = iosAppLink;
      // event.preventDefault();
      (window as any).open(iosAppLink, "_self");
      this.applink = iosAppLink;
    }
    else if (os == 'Android') {
      let androidAppLink = this.route.snapshot.data.DownloadLinksService.key_value.android_app_url;
      if(isDevMode()) console.log(androidAppLink);
      // //this.router.navigate(androidAppLink);
      // window.location.href = androidAppLink;
      // event.preventDefault();
      (window as any).open(androidAppLink, "_self");
      this.applink = androidAppLink;
    }
    else {
      if(isDevMode()) console.log('Not iOS nor Android');
      this.router.navigate(['/']);
    }
  }

  getOS() : string {
    let uA = navigator.userAgent || navigator.vendor;
    if ((/iPad|iPhone|iPod/.test(uA)) || (uA.includes('Mac') && 'ontouchend' in document)) return 'iOS';

    let i, os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
    for (i = 0; i < os.length; i++) if (new RegExp(os[i], 'i').test(uA)) return os[i];
  }

  addTrackingEvent(category:string, label:string, value:string) : void {
    gtag('event', 'Download_App', {
    'event_category': category,
    'event_label': label,
    'value': value })
    }

}
