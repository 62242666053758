import { Component, OnInit,HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { isNgTemplate } from '@angular/compiler';
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  
  privacyPolicy=""
  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document
    ) { }

  ngOnInit(): void {
    this.setPrivacyPolicy()
    this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.setPrivacyPolicy()
    })
  }

  setPrivacyPolicy(){
    var lang = this.translateService.currentLang!=undefined? this.translateService.currentLang:this.translateService.defaultLang;
    var langNum = lang==='en'? 14:15;
    var policyBody=(this.document.getElementsByClassName("my-5 container")[0] as HTMLHtmlElement)
    policyBody.style.paddingTop="20px"
    lang === "ar" ? (this.document.getElementsByClassName("my-5 container")[0] as HTMLHtmlElement).style.textAlign='right':(this.document.getElementsByClassName("my-5 container")[0] as HTMLHtmlElement).style.textAlign='left' 
    this.privacyPolicy=this.route.snapshot.data.OrderApiService.find(item => item.language_id == langNum).faq;
  }

  @HostListener('window:load')
  onLoad() {
    this.setPrivacyPolicy()
  }

}
