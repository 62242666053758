import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { AppModule } from 'src/app/app.module';
import { AppComponent } from 'src/app/app.component';
import { ConfigService } from 'src/app/services/config.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class HeaderComponent implements OnInit {

    location: any;
    logo: any;
    isEnglish;
    showAU;

    constructor(
        private translateService: TranslateService,
        private router: Router,
        location: Location,
        @Inject(DOCUMENT) private document: Document,
        private configService: ConfigService
    ) { 
        this.isEnglish = translateService.defaultLang === 'en';
        // console.log('const',this.isEnglish);
        this.showAU = configService.isAUActive();
    }

    changeLangage(lang: string) {
        this.translateService.setDefaultLang(lang);
        this.translateService.use(lang);
        this.isEnglish = this.translateService.currentLang == 'en';
        let htmlTag = this.document.getElementsByTagName("body")[0] ;
        htmlTag.dir = lang === "ar" ? "rtl" : "ltr";
     }

    ngOnInit() {
        this.router.events
            .subscribe((event) => {
                if ( event instanceof NavigationEnd ) {
                    this.location = this.router.url;
                    if (this.location === '/digital-marketing') {
                        this.logo = 'logo2.png';
                    } else {
                        this.logo = 'logo.png';
                    }
                }
            });
    }

}

