<!-- Start SaaS Main Banner -->
<div class="main-banner saas-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row h-100 justify-content-center align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image">
                            <img src="assets/img/saas-shape/arrow.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s" alt="arrow">
                            <img src="assets/img/saas-shape/box1.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="box1">
                            <img src="assets/img/saas-shape/boy1.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="0.6s" alt="boy1">
                            <img src="assets/img/saas-shape/boy2.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="boy2">
                            <img src="assets/img/saas-shape/boy3.png" class="wow animate__animated animate__bounceIn" data-wow-delay="0.6s" alt="boy3">
                            <img src="assets/img/saas-shape/digital-screen.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s" alt="digital-screen">
                            <img src="assets/img/saas-shape/filter1.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="filter1">
                            <img src="assets/img/saas-shape/filter2.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="filter2">
                            <img src="assets/img/saas-shape/filter3.png" class="wow animate__animated animate__rotateIn" data-wow-delay="0.6s" alt="filter3">
                            <img src="assets/img/saas-shape/girl1.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="girl1">
                            <img src="assets/img/saas-shape/girl2.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="girl2">
                            <img src="assets/img/saas-shape/monitor.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="monitor">
                            <img src="assets/img/saas-shape/main-image.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="main-image.png">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content pl-4">
                            <h1>Manage your business strategy in one placeholder</h1>
                            <p>Our passion to work hard and deliver excellent results. It could solve the needs of your customers and develop innovation.</p>
                            <a routerLink="/" class="btn btn-primary">Get Started</a>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"> Watch Video <i class="icofont-play-alt-3"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-gray shape-1"></div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape4 rotateme"><img src="assets/img/shape4.png" alt="img"></div>
</div>
<!-- End SaaS Main Banner -->

<!-- Start Features Area -->
<section class="features-area saas-features ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Amazing Features</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-lock"></i>
                    </div>
                    <h3>Quick Access</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-heart-eyes"></i>
                    </div>
                    <h3>Easily Manage</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-lock"></i>
                    </div>
                    <h3>Safty</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-camera"></i>
                    </div>
                    <h3>Camera Filter</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-responsive"></i>
                    </div>
                    <h3>Fast and optimized</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-hand-drag1"></i>
                    </div>
                    <h3>Drag and Drop</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="features-inner-area">
        <div class="container-fluid">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-image">
                        <img src="assets/img/features-img1.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="features-inner-content">
                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-ui-call"></i>
                            </div>
                            <h3>Free Caliing Service</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-gift"></i>
                            </div>
                            <h3>Daily Free Gift</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-qr-code"></i>
                            </div>
                            <h3>QR Code Scaner</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Features Area -->

<!-- Start Overview Area -->
<section class="overview-section ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>More to Discover</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-1.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-power"></i>
                    </div>
                    <h3>Getting started page</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/about-us" class="btn btn-primary">Read More</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-anchor"></i>
                    </div>
                    <h3>Outdated comments toggling</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/about-us" class="btn btn-primary">Read More</a>
                </div>

                <div class="col-lg-6 col-md-6 app-fetured-item">
                    <div class="app-item item-one">
                        <i class="icofont-wink-smile"></i>
                        <h6>Community</h6>
                    </div>

                    <div class="app-item item-two">
                        <i class="icofont-ebook"></i>
                        <h6>Honest pricing</h6>
                    </div>

                    <div class="app-item item-three">
                        <i class="icofont-wink-smile"></i>
                        <h6>Management</h6>
                    </div>

                    <div class="app-item item-four">
                        <i class="icofont-stock-mobile"></i>
                        <h6>Mobile Design</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-3.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-users-social"></i>
                    </div>
                    <h3>Code review illustrations</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/about-us" class="btn btn-primary">Read More</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-ui-messaging"></i>
                    </div>
                    <h3>We provide proffesional staff</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/about-us" class="btn btn-primary">Read More</a>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-4.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Overview Area -->

<!-- Start Join Area -->
<section class="join-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="join-img">
                    <img src="assets/img/3.png" alt="img">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="join-content">
                    <span>Start 30 Days Free Trial</span>
                    <h3>Overall 400k+ Our Clients! Please Get Start Now</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                    <a routerLink="/contact-us" class="btn btn-primary">Join Now</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Join Area -->

<!-- Start CTA Area -->
<div class="cta-area">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-7 col-md-7">
                <p>Want a bite? You're in the right place!</p>
            </div>

            <div class="col-lg-5 col-md-5 text-end">
                <a routerLink="/contact-us" class="btn btn-primary">Take a Test Drive</a>
            </div>
        </div>
    </div>
</div>
<!-- End CTA Area -->

<!-- Start SAAS Tools Area -->
<section class="saas-tools ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Tools for Everyone</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="tab">
                    <ul class="tabs">
                        <li><a href="#">
                            <i class="icofont-dashboard-web"></i>
                            <br>
                            Dashboard
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-inbox"></i>
                            <br>
                            Inbox
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-ui-calendar"></i>
                            <br>
                            Calendar
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-copy-invert"></i>
                            <br>
                            Invoicing
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-wrench"></i>
                            <br>
                            Reporting
                        </a></li>
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mt-0">
                                        <h3>Dashboard</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Creative Design</li>
                                            <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                            <li><i class="icofont-ui-check"></i> Modern Design</li>
                                            <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/features-img1.png" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/features-img1.png" alt="img">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mb-0">
                                        <h3>Inbox</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Creative Design</li>
                                            <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                            <li><i class="icofont-ui-check"></i> Modern Design</li>
                                            <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mt-0">
                                        <h3>Calendar</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Creative Design</li>
                                            <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                            <li><i class="icofont-ui-check"></i> Modern Design</li>
                                            <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/features-img1.png" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/features-img1.png" alt="img">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mb-0">
                                        <h3>Invoicing</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Creative Design</li>
                                            <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                            <li><i class="icofont-ui-check"></i> Modern Design</li>
                                            <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mt-0">
                                        <h3>Reporting</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Creative Design</li>
                                            <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                            <li><i class="icofont-ui-check"></i> Modern Design</li>
                                            <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/features-img1.png" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
</section>
<!-- End SAAS Tools Area -->

<app-funfact></app-funfact>

<!-- Start Work Process Area -->
<section class="saas-work-process ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Work Process</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-calendar"></i>
                    </div>
                    <h3>Plan</h3>
                    <p>Morbi pharetra sapien ut mattis.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-code-alt"></i>
                    </div>
                    <h3>Develop</h3>
                    <p>Morbi pharetra sapien ut mattis.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-server"></i>
                    </div>
                    <h3>Lounch</h3>
                    <p>Morbi pharetra sapien ut mattis.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-check"></i>
                    </div>
                    <h3>Reporting</h3>
                    <p>Morbi pharetra sapien ut mattis.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Work Process Area -->

<!-- Start Pricing Area -->
<section class="pricing-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Easy Pricing Plans</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="pricingTable">
                    <div class="pricing-icon">
                        <i class="icofont-globe"></i>
                    </div>
                    <div class="price-Value">
                        <span class="currency">$</span>15
                    </div>
                    <span class="month">/Month</span>
                    <div class="pricingHeader">
                        <h3 class="title">Personal</h3>
                    </div>
                    <div class="pricing-content">
                        <ul>
                            <li class="active">Send 5 invoices and quotes</li>
                            <li class="active">2 Clients and Products</li>
                            <li class="active">Invoicing and Payments</li>
                            <li class="active">Enter 5 bills</li>
                            <li class="active">Data Security and Backups</li>
                            <li>Unlimited Staff Accounts</li>
                            <li>Web Booking Widget</li>
                            <li>Monthly Reports and Analytics</li>
                            <li>24/7 Support</li>
                        </ul>
                    </div>
                    <a routerLink="/" class="btn btn-primary">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="pricingTable">
                    <div class="pricing-icon">
                        <i class="icofont-globe"></i>
                    </div>
                    <div class="price-Value">
                        <span class="currency">$</span>39
                    </div>
                    <span class="month">/Month</span>
                    <div class="pricingHeader">
                        <h3 class="title">Business</h3>
                    </div>
                    <div class="pricing-content">
                        <ul>
                            <li class="active">Send 5 invoices and quotes</li>
                            <li class="active">2 Clients and Products</li>
                            <li class="active">Invoicing and Payments</li>
                            <li class="active">Enter 5 bills</li>
                            <li class="active">Data Security and Backups</li>
                            <li class="active">Unlimited Staff Accounts</li>
                            <li class="active">Web Booking Widget</li>
                            <li>Monthly Reports and Analytics</li>
                            <li>24/7 Support</li>
                        </ul>
                    </div>
                    <a routerLink="/" class="btn btn-primary">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="pricingTable">
                    <div class="pricing-icon">
                        <i class="icofont-globe"></i>
                    </div>
                    <div class="price-Value">
                        <span class="currency">$</span>99
                    </div>
                    <span class="month">/Month</span>
                    <div class="pricingHeader">
                        <h3 class="title">Enterprise</h3>
                    </div>
                    <div class="pricing-content">
                        <ul>
                            <li class="active">Send 5 invoices and quotes</li>
                            <li class="active">2 Clients and Products</li>
                            <li class="active">Invoicing and Payments</li>
                            <li class="active">Enter 5 bills</li>
                            <li class="active">Data Security and Backups</li>
                            <li class="active">Unlimited Staff Accounts</li>
                            <li class="active">Web Booking Widget</li>
                            <li class="active">Monthly Reports and Analytics</li>
                            <li class="active">24/7 Support</li>
                        </ul>
                    </div>
                    <a routerLink="/" class="btn btn-primary">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing Area -->

<!-- Start Get Started Area -->
<section class="get-started ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Get Started Free</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="get-started-form">
            <form>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Name">
                </div>
                
                <div class="form-group">
                    <input type="email" class="form-control" placeholder="Email">
                </div>

                <div class="form-group">
                    <input type="password" class="form-control" placeholder="Password">
                </div>

                <button type="submit" class="btn btn-primary">Sign Up</button>
                <p>or use your <a href="#">Facebook Account</a></p>
            </form>
        </div>
    </div>

    <canvas id="canvas"></canvas>
</section>
<!-- End Get Started Area -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Testimonials</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/client1.jpg" alt="client">
                    <h3>Alit John</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/client2.jpg" alt="client">
                    <h3>Alit John</h3>
                    <span>SEO Expert</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/client3.jpg" alt="client">
                    <h3>Steven John</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/client4.jpg" alt="client">
                    <h3>David Warner</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
            </div>
        </div>
    </div>

    <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff"/>
    </svg>
</section>
<!-- End Feedback Area -->

<app-partner></app-partner>