import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-customlinks',
  templateUrl: './customlinks.component.html',
  styleUrls: ['./customlinks.component.scss']
})
export class CustomlinksComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private location: Location) { }

  ngOnInit(): void {}
}
