<!-- Start Fun Facts Area -->
<section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-users"></i>
                    </div>
                    <h3><span class="count">806</span></h3>
                    <p>Satisfied Client</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-thumbs-up"></i>
                    </div>
                    <h3><span class="count">202</span></h3>
                    <p>Appriciation</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-diamond"></i>
                    </div>
                    <h3><span class="count">10</span></h3>
                    <p>Response time</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-wrench"></i>
                    </div>
                    <h3><span class="count">512</span></h3>
                    <p>Data Entry</p>
                </div>
            </div>
        </div>
    </div>

    <div class="fun-facts-inner-area">
        <div class="container-fluid">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="fun-facts-image">
                        <img src="assets/img/features-img2.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="fun-facts-inner-content">
                        <h3>Build Beautiful Interface Into Your Application</h3>
                        <p>Funding freemium technology focus equity bootstrapping usernce niche market. Seed round agile development growth hacking retur investment alpha. Investor advisor marketing pitch gen scrum project.</p>
                        <p>Responsive web design. Agile development innovator termsheet is users interface pitch scrum project research & development.</p>
                        <ul>
                            <li>Unimited Video Call</li>
                            <li>Add Favourite contact</li>
                            <li>Camera Filter</li>
                        </ul>
                        <a href="#" class="btn btn-primary">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->
