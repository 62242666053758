import { ActivatedRoute } from '@angular/router';
import { Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Component, OnInit, HostListener } from '@angular/core';
import { DownloadLinksService } from 'src/app/utils/APIs/download-links.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  email=""
  phone=""
  constructor(  
  @Inject(DOCUMENT) private document: Document,
  private route: ActivatedRoute,
  private downloadLinksService:DownloadLinksService
  ) {

   }


  ngOnInit() {
    this.setEmail()
    this.setPhone()    
}
@HostListener('window:load')
onLoad() {
    this.setEmail()    
    this.setPhone()       
}
setEmail(){
  this.email=this.downloadLinksService.footerData.adminDetails[0].email
  this.document.getElementById("footerEmailLink").setAttribute("href","mailto:"+this.email)
  this.document.getElementById("footerEmailLink2").setAttribute("href","mailto:"+this.email)
  }

  setPhone(){
    this.phone=this.downloadLinksService.footerData.adminDetails[0].phone_number
    this.document.getElementById("footerPhoneLink").setAttribute("href","https://wa.me/966"+this.phone)
  }

}
