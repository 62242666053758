<!-- Start App Main Banner -->
<div class="main-banner app-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12 p-4 app-download">
                        <div class="section-title">
                            <h2 style="padding-bottom:10px;">iSupply</h2>
                            <div class="bar" style="background-color: white; margin-top:1px; width: 100px;"></div>
                            <p style="color: white;" translate>Landing-page-intro</p>
                            <p style="color: white;font-weight: bold;" translate>Landing-page-opetions</p>
                            <div class="download-btn">
                                <a href="" id="androidDownloadingLink" target="_blank" translate><i class="icofont-brand-android-robot"></i> Landing-page-available <span translate>Landing-page-op1</span></a>
                                <a href="" id="iosDownloadingLink" target="_blank"translate><i class="icofont-brand-apple"></i> Landing-page-available <span translate>Landing-page-op2</span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="app-image">
                            <img src="assets/img/app-img4.png" alt="image">
                            <img src="assets/img/app-img2.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="creative-shape"><img src="assets/img/bg3.png" alt="bg" style="width: 100%;"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape1" style="animation: movebounce 5s linear infinite;"><img src="assets/img/shape10.svg" alt="img"></div>
    <!-- <div class="shape2 shape-1 bg-gray" style="left: 1700px;" ></div> -->
    <!-- <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div> -->
    <div class="shape4 rotateme"><img src="assets/img/shape4.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
</div>
<!-- End App Main Banner -->

<!-- <app-partner></app-partner> -->

<!-- Start Features Area -->
<section class="features-area app-features ptb-100">
    <div class="container">
        <div class="section-title text-center">
            <h2 translate>Landing-page-why-us</h2>
            <div class="bar"></div>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>

        <div class="row text-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-features-box" style="padding: 17px;">
                    <div class="icon">
                        <i class="icofont-site-map"></i>
                    </div>
                    <h3 translate>Landing-page-why-1</h3>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="icofont-money"></i>
                    </div>
                    <h3 translate>Landing-page-why-2</h3>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="icofont-vehicle-delivery-van"></i>
                    </div>
                    <h3 translate>Landing-page-why-3</h3>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="features-inner-area">
        <div class="container-fluid">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-image">
                        <img src="assets/img/features-img1.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="features-inner-content">
                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-ui-call"></i>
                            </div>
                            <h3>Free Caliing Service</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-gift"></i>
                            </div>
                            <h3>Daily Free Gift</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-qr-code"></i>
                            </div>
                            <h3>QR Code Scaner</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Features Area -->

<!-- Start How App Work -->
<!-- <section class="how-app-work ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>How App Works</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="app-mobile">
                    <img src="assets/img/app-mobile.png" alt="mobile">
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-app-works">
                    <div class="icon">
                        <i class="icofont-downloaded"></i>
                    </div>
                    <h3>Download</h3>
                    <p>Duis cursus dictum enim dictum venenatis. Orci varius natoque penatibus et magnis dis parturient montes.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-app-works">
                    <div class="icon">
                        <i class="icofont-settings"></i>
                    </div>
                    <h3>Setup It</h3>
                    <p>Duis cursus dictum enim dictum venenatis. Orci varius natoque penatibus et magnis dis parturient montes.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-app-works">
                    <div class="icon">
                        <i class="icofont-thumbs-up"></i>
                    </div>
                    <h3>Enjoy it</h3>
                    <p>Duis cursus dictum enim dictum venenatis. Orci varius natoque penatibus et magnis dis parturient montes.</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End How App Work -->

<!-- Start Overview Area -->
<!-- <section class="overview-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>More to Discover</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-app1.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-power"></i>
                    </div>
                    <h3>Fast and Smooth</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/about-us" class="btn btn-primary">Read More</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-share-alt"></i>
                    </div>
                    <h3>Share your passion to the world</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/about-us" class="btn btn-primary">Read More</a>
                </div>

                <div class="col-lg-6 col-md-6 app-fetured-item">
                    <div class="app-item item-one">
                        <i class="icofont-wink-smile"></i>
                        <h6>Community</h6>
                    </div>

                    <div class="app-item item-two">
                        <i class="icofont-ebook"></i>
                        <h6>Honest pricing</h6>
                    </div>

                    <div class="app-item item-three">
                        <i class="icofont-wink-smile"></i>
                        <h6>Management</h6>
                    </div>

                    <div class="app-item item-four">
                        <i class="icofont-stock-mobile"></i>
                        <h6>Mobile Design</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-app3.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-ui-messaging"></i>
                    </div>
                    <h3>Constant Updates</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/about-us" class="btn btn-primary">Read More</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-users-social"></i>
                    </div>
                    <h3>We provide proffesional staff</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/about-us" class="btn btn-primary">Read More</a>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-app2.png" alt="image">
                </div>
            </div>
        </div>

        <div class="overview-box modern-flexible">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-4 col-md-6">
                    <div class="single-item right-icon">
                        <div class="icon">
                            <i class="icofont-lamp-light"></i>
                        </div>
                        <h3>Stunning Showcases</h3>
                        <p>Nam fermentum, arcu nec porta aliquam, lacus lorem efficitur lectus, quis sagittis tellus erat quis.</p>
                    </div>

                    <div class="single-item right-icon">
                        <div class="icon">
                            <i class="icofont-responsive"></i>
                        </div>
                        <h3>Fully Customizable</h3>
                        <p>Nam fermentum, arcu nec porta aliquam, lacus lorem efficitur lectus, quis sagittis tellus erat quis.</p>
                    </div>

                    <div class="single-item right-icon">
                        <div class="icon">
                            <i class="icofont-ui-timer"></i>
                        </div>
                        <h3>Engaging Tours</h3>
                        <p>Nam fermentum, arcu nec porta aliquam, lacus lorem efficitur lectus, quis sagittis tellus erat quis.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12 overview-img">
                    <img src="assets/img/feature-img-with-app.png" alt="image">
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-item">
                        <div class="icon">
                            <i class="icofont-ui-timer"></i>
                        </div>
                        <h3>Engaging Tours</h3>
                        <p>Nam fermentum, arcu nec porta aliquam, lacus lorem efficitur lectus, quis sagittis tellus erat quis.</p>
                    </div>

                    <div class="single-item">
                        <div class="icon">
                            <i class="icofont-star-alt-2"></i>
                        </div>
                        <h3>Exquisite Design</h3>
                        <p>Nam fermentum, arcu nec porta aliquam, lacus lorem efficitur lectus, quis sagittis tellus erat quis.</p>
                    </div>

                    <div class="single-item">
                        <div class="icon">
                            <i class="icofont-ui-timer"></i>
                        </div>
                        <h3>Engaging Tours</h3>
                        <p>Nam fermentum, arcu nec porta aliquam, lacus lorem efficitur lectus, quis sagittis tellus erat quis.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section> -->
<!-- End Overview Area -->

<!-- Start App Funfacts Area -->
<!-- <section class="app-funfacts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-download"></i>
                    </div>
                    <h3><span class="count">1.5</span>B</h3>
                    <p>Download</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-star"></i>
                    </div>
                    <h3><span class="count">4.8</span></h3>
                    <p>Rating</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-user-suited"></i>
                    </div>
                    <h3><span class="count">500</span>M</h3>
                    <p>Active User</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-thumbs-up"></i>
                    </div>
                    <h3><span class="count">13</span>K</h3>
                    <p>Updates</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End App Funfacts Area -->

<!-- Start SAAS Tools Area -->
<!-- <section class="saas-tools ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Tools for Everyone</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="tab">
                    <ul class="tabs">
                        <li><a href="#">
                            <i class="icofont-dashboard-web"></i>
                            <br>
                            Dashboard
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-inbox"></i>
                            <br>
                            Inbox
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-ui-calendar"></i>
                            <br>
                            Calendar
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-copy-invert"></i>
                            <br>
                            Invoicing
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-wrench"></i>
                            <br>
                            Reporting
                        </a></li>
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mt-0">
                                        <h3>Dashboard</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Creative Design</li>
                                            <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                            <li><i class="icofont-ui-check"></i> Modern Design</li>
                                            <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/features-img1.png" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/features-img1.png" alt="img">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mb-0">
                                        <h3>Inbox</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Creative Design</li>
                                            <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                            <li><i class="icofont-ui-check"></i> Modern Design</li>
                                            <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mt-0">
                                        <h3>Calendar</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Creative Design</li>
                                            <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                            <li><i class="icofont-ui-check"></i> Modern Design</li>
                                            <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/features-img1.png" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/features-img1.png" alt="img">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mb-0">
                                        <h3>Invoicing</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Creative Design</li>
                                            <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                            <li><i class="icofont-ui-check"></i> Modern Design</li>
                                            <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mt-0">
                                        <h3>Reporting</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Creative Design</li>
                                            <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                            <li><i class="icofont-ui-check"></i> Modern Design</li>
                                            <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/features-img1.png" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End SAAS Tools Area -->

<!-- Start Screenshot Area -->
<!-- <section class="screenshot-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Screenshot</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="screenshot-contain">
                    <img src="assets/img/mobile.png" class="mobile-light" alt="mobile">

                    <div class="row">
                        <div class="screenshot-slides owl-carousel owl-theme">
                            <div class="col-lg-12">
                                <div class="screenshot-item">
                                    <img src="assets/img/screenshot-img1.png" alt="screenshot">
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="screenshot-item">
                                    <img src="assets/img/screenshot-img4.png" alt="screenshot">
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="screenshot-item">
                                    <img src="assets/img/screenshot-img2.png" alt="screenshot">
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="screenshot-item">
                                    <img src="assets/img/screenshot-img3.png" alt="screenshot">
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="screenshot-item">
                                    <img src="assets/img/screenshot-img4.png" alt="screenshot">
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="screenshot-item">
                                    <img src="assets/img/screenshot-img1.png" alt="screenshot">
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="screenshot-item">
                                    <img src="assets/img/screenshot-img2.png" alt="screenshot">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Screenshot Area -->

<!-- Start Team Area -->
<!-- <section class="team-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Our Smart Team</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="team-slides owl-carousel owl-theme">
            <div class="single-team-member">
                <img src="assets/img/team-img1.jpg" alt="team">

                <div class="team-content">
                    <h3>John Doe</h3>
                    <span>CEO</span>
                    <ul>
                        <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member">
                <img src="assets/img/team-img2.jpg" alt="team">

                <div class="team-content">
                    <h3>Steven Smith</h3>
                    <span>Developer</span>
                    <ul>
                        <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member">
                <img src="assets/img/team-img3.jpg" alt="team">

                <div class="team-content">
                    <h3>David Luiz</h3>
                    <span>Designer</span>
                    <ul>
                        <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member">
                <img src="assets/img/team-img4.jpg" alt="team">

                <div class="team-content">
                    <h3>Park Tim</h3>
                    <span>SEO Expert</span>
                    <ul>
                        <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member">
                <img src="assets/img/team-img5.jpg" alt="team">

                <div class="team-content">
                    <h3>Doglas Costa</h3>
                    <span>Manager</span>
                    <ul>
                        <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Team Area -->

<!-- Start Pricing Area -->
<!-- <section class="pricing-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Easy Pricing Plans</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="pricing-table">
                    <div class="price-header">
                        <h3 class="title">Personal</h3>
                        <div class="price"><span class="dollar">$</span>15<span class="month">/Mo</span></div>
                    </div>

                    <div class="price-body">
                        <ul>
                            <li><b>Free</b> Security Service</li>
                            <li><b>1x</b> Security Service</li>
                            <li><b>Unlimited</b> Security Service</li>
                            <li><b>1x</b> Dashboard Access</li>
                            <li><b>3x</b> Job Listings</li>
                            <li><b>5x</b> invoices and quotes</li>
                        </ul>
                    </div>

                    <div class="price-footer">
                        <a class="btn btn-primary" routerLink="/">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="pricing-table">
                    <div class="price-header">
                        <h3 class="title">Business</h3>
                        <div class="price"><span class="dollar">$</span>39<span class="month">/Mo</span></div>
                    </div>

                    <div class="price-body">
                        <ul>
                            <li><b>Free</b> Security Service</li>
                            <li><b>1x</b> Security Service</li>
                            <li><b>Unlimited</b> Security Service</li>
                            <li><b>1x</b> Dashboard Access</li>
                            <li><b>3x</b> Job Listings</li>
                            <li><b>5x</b> invoices and quotes</li>
                        </ul>
                    </div>

                    <div class="price-footer">
                        <a class="btn btn-primary" routerLink="/">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="pricing-table">
                    <div class="price-header">
                        <h3 class="title">Enterprise</h3>
                        <div class="price"><span class="dollar">$</span>99<span class="month">/Mo</span></div>
                    </div>

                    <div class="price-body">
                        <ul>
                            <li><b>Free</b> Security Service</li>
                            <li><b>1x</b> Security Service</li>
                            <li><b>Unlimited</b> Security Service</li>
                            <li><b>1x</b> Dashboard Access</li>
                            <li><b>3x</b> Job Listings</li>
                            <li><b>5x</b> invoices and quotes</li>
                        </ul>
                    </div>

                    <div class="price-footer">
                        <a class="btn btn-primary" routerLink="/">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <canvas id="canvas"></canvas>
</section> -->
<!-- End Pricing Area -->

<!-- Start App Download Area -->
<!-- <section class="app-download ptb-100 bg-gray">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="section-title">
                    <h2>Download Our Apps Today</h2>
                    <div class="bar"></div>
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account the system and expound the actual teachings of the great explore.</p>
                    <div class="download-btn">
                        <a href="#" target="_blank"><i class="icofont-brand-android-robot"></i> Available On <span>Google Store</span></a>
                        <a href="#" target="_blank"><i class="icofont-brand-apple"></i> Available On <span>Apple Store</span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="download-image">
                    <img src="assets/img/app-img1.png" alt="image">
                    <img src="assets/img/app-img2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End App Download Area -->

<!-- Start Blog Area -->
<!-- <section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest News</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img1.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 23, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Most Popular New Business Apps</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img2.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 16, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Best Marketing Management Tools</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 14, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">3 WooCommerce Plugins to Boost Sales</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img4.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 06, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">CakeMail Review – Design Custom Emails</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img5.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 04, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Most Popular New Apps in 2019</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 26, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Fastest Growing Apps in 2019</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Blog Area -->

<!-- Start Feedback Area -->
<!-- <section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Testimonials</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/client1.jpg" alt="client">
                    <h3>Alit John</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/client2.jpg" alt="client">
                    <h3>Alit John</h3>
                    <span>SEO Expert</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/client3.jpg" alt="client">
                    <h3>Steven John</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/client4.jpg" alt="client">
                    <h3>David Warner</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
            </div>
        </div>
    </div>

    <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff"/>
    </svg>
</section> -->
<!-- End Feedback Area -->

<!-- Start Subscribe Area -->
<!-- <section class="subscribe-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Connect With Our Community</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <form class="newsletter-form">
            <input type="email" class="form-control" placeholder="Enter your email address" name="email" required autocomplete="off">
            <button class="btn btn-primary" type="submit">Subscribe</button>
        </form>
    </div>
</section> -->
<!-- End Subscribe Area -->