<!-- Start Footer Area -->
<footer class="footer-area ptb-100 pb-0 bg-image">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/Isupply.png" alt="logo2"></a>
                    <p translate>Footer-contact-us<br><a id="footerEmailLink" href="">info@isupply.sa</a></p>
                    <ul class="social-list">
                        <!-- <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li> -->
                        <li><a href="https://twitter.com/isupplyapp" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a id="footerPhoneLink" href="" target="_blank"><i class="icofont-whatsapp"></i></a></li>
                        <li><a href="https://linkedin.com/company/isupplyapp/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        <li><a id="footerEmailLink2" href="mailto:info@isupply.sa" target="_blank"><i class="icofont-mail"></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li> -->
                    </ul>
                </div>
            </div>

            <!-- <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget pl-5">
                    <h3>Company</h3>

                    <ul class="list">
                        <li><a routerLink="/">About</a></li>
                        <li><a routerLink="/">Carrers</a></li>
                        <li><a routerLink="/">Awards</a></li>
                        <li><a routerLink="/">User Program</a></li>
                        <li><a routerLink="/">Locations</a></li>
                        <li><a routerLink="/">Login</a></li>
                    </ul>
                </div>
            </div> -->

            <!-- <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Products</h3>

                    <ul class="list">
                        <li><a routerLink="/">Integrations</a></li>
                        <li><a routerLink="/">API</a></li>
                        <li><a routerLink="/">Pricing</a></li>
                        <li><a routerLink="/">Documentation</a></li>
                        <li><a routerLink="/">Sign Up</a></li>
                        <li><a routerLink="/">Release Notes</a></li>
                    </ul>
                </div>
            </div> -->

            <!-- <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>

                    <ul class="list">
                        <li><a routerLink="/">About</a></li>
                        <li><a routerLink="/">Contact</a></li>
                        <li><a routerLink="/">FAQ</a></li>
                        <li><a routerLink="/">Press</a></li>
                    </ul>
                </div>
            </div> -->
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center" >
                <div class="col-lg-6 col-md-6" >
                    <ul>
                        <li class="li2"><p translate>Footer-all-rights</p></li>
                        <li class="li2"><p> &copy;2024.</p></li>
                    </ul>
                </div>
                <div class="col-lg-6 col-md-6">
                    <!-- <ul class="ui2"> -->
                    <ul >
                        <li class="li1"><a routerLink="/Terms-and-conditions" translate>Footer-Terms&Conditions</a></li>
                        <li class="li1"><a >|</a></li>
                        <li class="li1"><a routerLink="/Privacy-Policy"  translate>Footer-privacy-policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Back Top top -->
<div class="back-to-top">Top</div>
<!-- End Back Top top -->