import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Title } from '@angular/platform-browser';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  aboutUs = ""
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private configService: ConfigService,
    @Inject(DOCUMENT) private document: Document
  ) {
    // this.titleService.setTitle('Kilian - Angular 14 About Us');
    if (!configService.isAUActive())
      router.navigate(['/']);
    else {
      this.setAboutUs()
      this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
        this.setAboutUs()
        this.aboutUsTitleStyling()
      })
    }
  }
  ngOnInit() {
    this.aboutUsTitleStyling()
  }

  setAboutUs() {
    var lang;
    this.translateService.currentLang != undefined ? lang = this.translateService.currentLang : lang = this.translateService.defaultLang
    var langNum;
    lang === 'en' ? langNum = 14 : langNum = 15
    // lang === "en" ? (this.document.getElementsByClassName("section-title")[0] as HTMLHtmlElement).style.textAlign='right':(this.document.getElementsByClassName("section-title")[0] as HTMLHtmlElement).style.textAlign='left' 
    this.aboutUs = this.route.snapshot.data.OrderApiService.find(item => item.language_id == langNum).about_us;

  }

  @HostListener('window:load')
  onLoad() {
    this.aboutUsTitleStyling()
  }

  aboutUsTitleStyling() {
    var lang2;
    console.log((this.document.getElementsByClassName("section-title"))[0]);
    this.translateService.currentLang != undefined ? lang2 = this.translateService.currentLang : lang2 = this.translateService.defaultLang
    // console.log("hhhhhhh",lang2);
    lang2 === "ar" ? (this.document.getElementsByClassName("section-title")[0] as HTMLHtmlElement).style.textAlign = 'right' : (this.document.getElementsByClassName("section-title")[0] as HTMLHtmlElement).style.textAlign = 'left'
  }
}