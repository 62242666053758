<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <!-- <h2 translate>Footer-privacy-policy</h2> -->
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<div class="py-5">
    <div class="my-5 container">
        <p>
            you are being directed to the application ...
        </p>
        <a class="btn btn-primary" [href]="this.applink" target="_self">if you are not redirected, please click here to download.</a>
    </div>
</div>