import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  TermsAndConditions=""
  constructor(    
    private route: ActivatedRoute,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.setTermsAndConditions()
    this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.setTermsAndConditions()
    })
  }

  setTermsAndConditions(){
    var lang;
    this.translateService.currentLang!=undefined? lang=this.translateService.currentLang:lang=this.translateService.defaultLang
    var langNum;
    lang==='en'? langNum=14:langNum=15
    this.TermsAndConditions=this.route.snapshot.data.OrderApiService.find(item => item.language_id == langNum).terms_and_conditions;
  }

}
