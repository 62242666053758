import { Component, OnInit, HostListener} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
@Component({
    selector: 'app-app-landing',
    templateUrl: './app-landing.component.html',
    styleUrls: ['./app-landing.component.scss']
})
export class AppLandingComponent implements OnInit {
    iosURL=""
    androidURL=""
    email=""
    phone=""
    constructor( private titleService: Title,
        @Inject(DOCUMENT) private document: Document,
        private route: ActivatedRoute, ) {
        this.titleService.setTitle(this.titleService.getTitle() + ' - Your reliable supplier');
    }

    ngOnInit() {
        this.setDownloadingLinks()
    }
    @HostListener('window:load')
    onLoad() {
        this.setDownloadingLinks()    
    }
    setDownloadingLinks(){
        this.iosURL=this.route.snapshot.data.DownloadLinksService.key_value.ios_app_url;
        this.androidURL=this.route.snapshot.data.DownloadLinksService.key_value.android_app_url
        //Android link flag
        var modifyAndroidLink=this.androidURL.indexOf('user')
        if(modifyAndroidLink!=-1){
            this.androidURL=this.androidURL.substring(0,this.androidURL.indexOf('user'))
            this.androidURL=this.androidURL.concat('customer')
        }
        this.document.getElementById("androidDownloadingLink").setAttribute("href",this.androidURL)
        this.document.getElementById("iosDownloadingLink").setAttribute("href",this.iosURL)        
    }
}